var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "landlordData-overview" },
    [
      _c("div"),
      _c(
        "div",
        { staticClass: "refresh-box" },
        [
          _c(
            "el-button",
            {
              staticClass: "refresh-btn",
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  _vm.dataDialogVisible = true
                }
              }
            },
            [
              _c("i", {
                staticClass: "el-icon-question",
                staticStyle: { "margin-right": "2px" }
              }),
              _vm._v("数据说明")
            ]
          ),
          _c("div", { staticStyle: { margin: "0 20px" } }, [
            _vm._v("更新时间：" + _vm._s(_vm.time))
          ]),
          _c(
            "el-button",
            {
              staticClass: "refresh-btn",
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  _vm.refresh()
                }
              }
            },
            [
              _c("i", {
                staticClass: "el-icon-refresh-right",
                staticStyle: { "margin-right": "2px" }
              }),
              _vm._v("刷新")
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "module" }, [
        _c("div", { staticClass: "module-content" }, [
          _c("div", { staticClass: "title" }, [_vm._v("房源数据")]),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticStyle: { "font-size": "28px" } }, [
                _vm._v(_vm._s(_vm.apartmentCount))
              ]),
              _c("img", {
                attrs: { src: require("../../assets/landlord-fangyuan.png") }
              }),
              _c("div", { staticClass: "module-box-title" }, [
                _vm._v("房源总数")
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticStyle: { "font-size": "28px" } }, [
                _vm._v(_vm._s(_vm.buildingCount))
              ]),
              _c("img", {
                attrs: { src: require("../../assets/landlord-loudong.png") }
              }),
              _c("div", { staticClass: "module-box-title" }, [
                _vm._v("楼栋总数")
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticStyle: { "font-size": "28px" } }, [
                _vm._v(_vm._s(_vm.roomCount))
              ]),
              _c("img", {
                attrs: { src: require("../../assets/landlord-fangjian.png") }
              }),
              _c("div", { staticClass: "module-box-title" }, [
                _vm._v("房间总数")
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticStyle: { "font-size": "28px" } }, [
                _vm._v(_vm._s(_vm.liveRoomCount))
              ]),
              _c("img", {
                attrs: {
                  src: require("../../assets/landlord-zaizhufangshu.png")
                }
              }),
              _c("div", { staticClass: "module-box-title" }, [
                _vm._v("在住房数")
              ])
            ]),
            _c("div", { staticClass: "line" }),
            _c("div", { staticClass: "module-box" }, [
              _c("div", { staticStyle: { "font-size": "28px" } }, [
                _vm._v(_vm._s(_vm.vacantRoomCount))
              ]),
              _c("img", {
                attrs: {
                  src: require("../../assets/landlord-kongzhifangjian.png")
                }
              }),
              _c("div", { staticClass: "module-box-title" }, [
                _vm._v("空置房数")
              ])
            ])
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "module-content",
            staticStyle: { "margin-left": "13px" }
          },
          [
            _c("div", { staticClass: "title" }, [_vm._v("设备数据")]),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "module-box" }, [
                _c("div", { staticStyle: { "font-size": "28px" } }, [
                  _vm._v(_vm._s(_vm.accessControlCount))
                ]),
                _c("img", {
                  attrs: { src: require("../../assets/landlord-menjin.png") }
                }),
                _c("div", { staticClass: "module-box-title" }, [
                  _vm._v("门禁总数")
                ])
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "module-box" }, [
                _c("div", { staticStyle: { "font-size": "28px" } }, [
                  _vm._v(_vm._s(_vm.lockCount))
                ]),
                _c("img", {
                  attrs: { src: require("../../assets/landlord-mensuo.png") }
                }),
                _c("div", { staticClass: "module-box-title" }, [
                  _vm._v("门锁总数")
                ])
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "module-box" }, [
                _c("div", { staticStyle: { "font-size": "28px" } }, [
                  _vm._v(_vm._s(_vm.accessControlOnlineCount))
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/landlord-zaixianmenjin.png")
                  }
                }),
                _c("div", { staticClass: "module-box-title" }, [
                  _vm._v("在线门禁")
                ])
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "module-box" }, [
                _c("div", { staticStyle: { "font-size": "28px" } }, [
                  _vm._v(_vm._s(_vm.accessControlOfflineCount))
                ]),
                _c("img", {
                  attrs: {
                    src: require("../../assets/landlord-lixianmenjin.png")
                  }
                }),
                _c("div", { staticClass: "module-box-title" }, [
                  _vm._v("离线门禁")
                ])
              ])
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "module" }, [
        _c(
          "div",
          {
            staticClass: "module-content",
            staticStyle: { width: "49.5%", height: "100%" }
          },
          [
            _c("div", { staticClass: "title" }, [_vm._v("人员数据")]),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "module-box" }, [
                _c("div", { staticClass: "count-num" }, [
                  _vm._v(_vm._s(_vm.houseKeeperCount))
                ]),
                _c("div", { staticClass: "cabinet-content-title" }, [
                  _vm._v("房管总数")
                ])
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "module-box" }, [
                _c("div", { staticClass: "count-num" }, [
                  _vm._v(_vm._s(_vm.liveingCount))
                ]),
                _c("div", { staticClass: "cabinet-content-title" }, [
                  _vm._v("在住人员总数")
                ])
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "module-box" }, [
                _c("div", { staticClass: "count-num" }, [
                  _vm._v(_vm._s(_vm.checkOutCount))
                ]),
                _c("div", { staticClass: "cabinet-content-title" }, [
                  _vm._v("退房人员总数")
                ])
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "module-box" }, [
                _c("div", { staticClass: "count-num" }, [
                  _vm._v(_vm._s(_vm.newCheckInCount))
                ]),
                _c("div", { staticClass: "cabinet-content-title" }, [
                  _vm._v("新入住人员总数")
                ])
              ])
            ]),
            _c("div", { staticClass: "line-x" }),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "module-box" }, [
                _c("div", { staticClass: "count-num" }, [
                  _vm._v(_vm._s(_vm.openAccessControlUserCount))
                ]),
                _c("div", { staticClass: "cabinet-content-title" }, [
                  _vm._v("开门禁人数")
                ])
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "module-box" }, [
                _c("div", { staticClass: "count-num" }, [
                  _vm._v(_vm._s(_vm.openAccessControlCount))
                ]),
                _c("div", { staticClass: "cabinet-content-title" }, [
                  _vm._v("开门禁人/次")
                ])
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "module-box" }, [
                _c("div", { staticClass: "count-num" }, [
                  _vm._v(_vm._s(_vm.openLockUserCount))
                ]),
                _c("div", { staticClass: "cabinet-content-title" }, [
                  _vm._v("开门锁人数")
                ])
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "module-box" }, [
                _c("div", { staticClass: "count-num" }, [
                  _vm._v(_vm._s(_vm.openLockCount))
                ]),
                _c("div", { staticClass: "cabinet-content-title" }, [
                  _vm._v("开门锁人/次")
                ])
              ])
            ])
          ]
        ),
        _c(
          "div",
          {
            staticStyle: {
              background: "#ffffff",
              padding: "10px 0 10px 10px",
              "margin-left": "13px",
              width: "50%",
              height: "100%"
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "title",
                staticStyle: { "margin-bottom": "10px" }
              },
              [_vm._v("在住用户性别统计")]
            ),
            _c("div", { style: { height: "322px" }, attrs: { id: "sex-pie" } })
          ]
        )
      ]),
      _c("div", { staticClass: "module" }, [
        _c(
          "div",
          {
            staticStyle: {
              background: "#ffffff",
              padding: "10px 0 10px 10px",
              width: "100%",
              "box-sizing": "border-box"
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [_vm._v("楼栋在住人数")]
            ),
            _c(
              "div",
              { staticStyle: { "max-width": "1608px", overflow: "auto" } },
              [
                _c("div", {
                  style: {
                    width: _vm.livingPeopleBuildingWidth,
                    height: "322px",
                    overflow: "auto"
                  },
                  attrs: { id: "livingPeopleBuilding" }
                })
              ]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "module" }, [
        _c(
          "div",
          {
            staticStyle: {
              background: "#ffffff",
              padding: "10px 0 10px 10px",
              width: "100%",
              "box-sizing": "border-box"
            }
          },
          [
            _c("div", { staticClass: "title" }, [_vm._v("入住人员统计")]),
            _c(
              "div",
              {
                staticStyle: {
                  "text-align": "right",
                  "margin-right": "64px",
                  "margin-bottom": "18px",
                  "max-width": "1548px"
                }
              },
              [
                _c(
                  "el-button",
                  {
                    class: [_vm.getCheckinPersonnelStyle(1)],
                    on: {
                      click: function($event) {
                        _vm.changeCheckinPersonnelData(1)
                      }
                    }
                  },
                  [_vm._v("按年")]
                ),
                _c(
                  "el-button",
                  {
                    class: [_vm.getCheckinPersonnelStyle(2)],
                    on: {
                      click: function($event) {
                        _vm.changeCheckinPersonnelData(2)
                      }
                    }
                  },
                  [_vm._v("按月")]
                ),
                _c(
                  "el-button",
                  {
                    class: [_vm.getCheckinPersonnelStyle(3)],
                    on: {
                      click: function($event) {
                        _vm.changeCheckinPersonnelData(3)
                      }
                    }
                  },
                  [_vm._v("按日")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "max-width": "1608px", overflow: "auto" } },
              [
                _c("div", {
                  style: { width: "1608px", height: "322px" },
                  attrs: { id: "check-in-personnel" }
                })
              ]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "module" }, [
        _c(
          "div",
          {
            staticStyle: {
              background: "#ffffff",
              padding: "10px 0 10px 10px",
              width: "100%",
              "box-sizing": "border-box"
            }
          },
          [
            _c("div", { staticClass: "title" }, [_vm._v("进出人员统计")]),
            _c(
              "div",
              {
                staticStyle: {
                  "text-align": "right",
                  "margin-right": "64px",
                  "margin-bottom": "18px",
                  "max-width": "1548px"
                }
              },
              [
                _c(
                  "el-button",
                  {
                    class: [_vm.getAccessPersonnelStyle(1)],
                    on: {
                      click: function($event) {
                        _vm.changeAccessPersonnelData(1)
                      }
                    }
                  },
                  [_vm._v("按年")]
                ),
                _c(
                  "el-button",
                  {
                    class: [_vm.getAccessPersonnelStyle(2)],
                    on: {
                      click: function($event) {
                        _vm.changeAccessPersonnelData(2)
                      }
                    }
                  },
                  [_vm._v("按月")]
                ),
                _c(
                  "el-button",
                  {
                    class: [_vm.getAccessPersonnelStyle(3)],
                    on: {
                      click: function($event) {
                        _vm.changeAccessPersonnelData(3)
                      }
                    }
                  },
                  [_vm._v("按日")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "max-width": "1608px", overflow: "auto" } },
              [
                _c("div", {
                  style: { width: "1608px", height: "322px" },
                  attrs: { id: "access-personnel" }
                })
              ]
            )
          ]
        )
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "数据说明",
            top: "10vh",
            visible: _vm.dataDialogVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dataDialogVisible = $event
            }
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticStyle: { "line-height": "25px", "margin-top": "-20px" } },
              [
                _c("div", { staticClass: "data-title" }, [_vm._v("房源数据")]),
                _vm._v("\n        房源总数=当前账号下发布或管理的房源总数；"),
                _c("br"),
                _vm._v(
                  "\n        楼栋总数=当前账号下发布或管理的房源下的楼栋总数；"
                ),
                _c("br"),
                _vm._v("\n        房间总数=当前账号下发布或管理的房间总数；"),
                _c("br"),
                _vm._v(
                  "\n        在住房间=当前账号下发布或管理的房间还处于在住状态的房间总数；"
                ),
                _c("br"),
                _vm._v(
                  "\n        空置房数=当前账号下发布或管理的房间无人居住的房间总数；"
                ),
                _c("br")
              ]
            ),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [_vm._v("设备数据")]),
              _vm._v(
                "\n        门禁总数=当前账号下发布或管理的房源，安装的门禁总数,仅统计L2+门禁数量；"
              ),
              _c("br"),
              _vm._v(
                "\n        门锁总数=当前账号下发布或管理的房源，安装的门锁总数；"
              ),
              _c("br"),
              _vm._v(
                "\n        在线门禁=当前账号下发布或管理的门禁，当前处于在线状态的门禁总数，仅统计L2+门禁在线数量；"
              ),
              _c("br"),
              _vm._v(
                "\n        离线门禁=当前账号下发布或管理的门禁，当前处于离线状态的门禁总数，仅统计L2+门禁离线数量；"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [_vm._v("人员数据")]),
              _vm._v(
                "\n        房管总数=房管员总人数，仅包括当前正在使用的房管员；已禁用的不包括；"
              ),
              _c("br"),
              _vm._v(
                "\n        在住人员总数=按照时间范围，在住、退房中或退房未结束的房间人员总数，包括房客和同住人；"
              ),
              _c("br"),
              _vm._v(
                "\n        退房人员总数=按照时间范围，统计已退房+退房中的人员总数；"
              ),
              _c("br"),
              _vm._v(
                "\n        新入住人员总数=按照时间范围，统计办理入住（排房或添加的同住人）的人员总数；"
              ),
              _c("br"),
              _vm._v(
                "\n        开门禁人数=按照时间范围，统计开门禁的人员总数；（手机蓝牙开门禁、远程开门禁、密码开门禁方式统计，仅统计L2+开门禁人数数量）；"
              ),
              _c("br"),
              _vm._v(
                "\n        开门禁人/次=按照时间范围，统计开门禁的总次数，仅统计L2+开门禁人/次数量；"
              ),
              _c("br"),
              _vm._v(
                "\n        开门锁人数=按照时间范围，统计开门锁的人员总数；(手机蓝牙开锁方式统计)；"
              ),
              _c("br"),
              _vm._v(
                "\n        开门锁人/次=按照时间范围，统计开门锁的总次数；"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("在住用户性别统计")
              ]),
              _vm._v("\n        按照性别属性统计在住用户的数据占比情况；"),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("楼栋在住人数")
              ]),
              _vm._v("\n        按照楼栋，统计每栋楼在住用户的人数；"),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("入住人员统计")
              ]),
              _vm._v("\n        按照时间范围，统计入住人员和退房人员情况；"),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("进出人员统计")
              ]),
              _vm._v(
                "\n        按照时间范围，统计时段内人员开门禁和开锁人员情况；"
              ),
              _c("br")
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }