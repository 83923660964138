<template>
  <div class="landlordData-overview">
    <div></div>
    <div class="refresh-box">
      <el-button
        type="text"
        class="refresh-btn"
        @click="dataDialogVisible = true"
        ><i class="el-icon-question" style="margin-right: 2px"></i
        >数据说明</el-button
      >
      <div style="margin: 0 20px">更新时间：{{ time }}</div>
      <el-button type="text" class="refresh-btn" @click="refresh()"
        ><i class="el-icon-refresh-right" style="margin-right: 2px"></i
        >刷新</el-button
      >
    </div>
    <div class="module">
      <div class="module-content">
        <div class="title">房源数据</div>
        <div class="content">
          <div class="module-box">
            <div style="font-size: 28px">{{ apartmentCount }}</div>
            <img src="../../assets/landlord-fangyuan.png" />
            <div class="module-box-title">房源总数</div>
          </div>
          <div class="line"></div>
          <div class="module-box">
            <div style="font-size: 28px">{{ buildingCount }}</div>
            <img src="../../assets/landlord-loudong.png" />
            <div class="module-box-title">楼栋总数</div>
          </div>
          <div class="line"></div>
          <div class="module-box">
            <div style="font-size: 28px">{{ roomCount }}</div>
            <img src="../../assets/landlord-fangjian.png" />
            <div class="module-box-title">房间总数</div>
          </div>
          <div class="line"></div>
          <div class="module-box">
            <div style="font-size: 28px">{{ liveRoomCount }}</div>
            <img src="../../assets/landlord-zaizhufangshu.png" />
            <div class="module-box-title">在住房数</div>
          </div>
          <div class="line"></div>
          <div class="module-box">
            <div style="font-size: 28px">{{ vacantRoomCount }}</div>
            <img src="../../assets/landlord-kongzhifangjian.png" />
            <div class="module-box-title">空置房数</div>
          </div>
        </div>
      </div>
      <div class="module-content" style="margin-left: 13px;">
        <div class="title">设备数据</div>
        <div class="content">
          <div class="module-box">
            <div style="font-size: 28px">{{ accessControlCount }}</div>
            <img src="../../assets/landlord-menjin.png" />
            <div class="module-box-title">门禁总数</div>
          </div>
          <div class="line"></div>
          <div class="module-box">
            <div style="font-size: 28px">{{ lockCount }}</div>
            <img src="../../assets/landlord-mensuo.png" />
            <div class="module-box-title">门锁总数</div>
          </div>
          <div class="line"></div>
          <div class="module-box">
            <div style="font-size: 28px">{{ accessControlOnlineCount }}</div>
            <img src="../../assets/landlord-zaixianmenjin.png" />
            <div class="module-box-title">在线门禁</div>
          </div>
          <div class="line"></div>
          <div class="module-box">
            <div style="font-size: 28px">{{ accessControlOfflineCount }}</div>
            <img src="../../assets/landlord-lixianmenjin.png" />
            <div class="module-box-title">离线门禁</div>
          </div>
        </div>
      </div>
    </div>
    <div class="module">
      <div class="module-content" style="width: 49.5%;height: 100%;">
        <div class="title">人员数据</div>
        <div class="content">
          <div class="module-box">
            <div class="count-num">{{ houseKeeperCount }}</div>
            <div class="cabinet-content-title">房管总数</div>
          </div>
          <div class="line"></div>
          <div class="module-box">
            <div class="count-num">{{ liveingCount }}</div>
            <div class="cabinet-content-title">在住人员总数</div>
          </div>
          <div class="line"></div>
          <div class="module-box">
            <div class="count-num">{{ checkOutCount }}</div>
            <div class="cabinet-content-title">退房人员总数</div>
          </div>
          <div class="line"></div>
          <div class="module-box">
            <div class="count-num">{{ newCheckInCount }}</div>
            <div class="cabinet-content-title">新入住人员总数</div>
          </div>
        </div>
        <div class="line-x"></div>
        <div class="content">
          <div class="module-box">
            <div class="count-num">{{ openAccessControlUserCount }}</div>
            <div class="cabinet-content-title">开门禁人数</div>
          </div>
          <div class="line"></div>
          <div class="module-box">
            <div class="count-num">{{ openAccessControlCount }}</div>
            <div class="cabinet-content-title">开门禁人/次</div>
          </div>
          <div class="line"></div>
          <div class="module-box">
            <div class="count-num">{{ openLockUserCount }}</div>
            <div class="cabinet-content-title">开门锁人数</div>
          </div>
          <div class="line"></div>
          <div class="module-box">
            <div class="count-num">{{ openLockCount }}</div>
            <div class="cabinet-content-title">开门锁人/次</div>
          </div>
        </div>
      </div>
      <div style="background: #ffffff; padding: 10px 0 10px 10px;margin-left: 13px;width: 50%;height: 100%;">
        <div class="title" style="margin-bottom: 10px">在住用户性别统计</div>
        <div id="sex-pie" :style="{ height: '322px' }"></div>
      </div>
    </div>
    <div class="module">
      <div style="background: #ffffff; padding: 10px 0 10px 10px;width: 100%;box-sizing: border-box;">
        <div class="title" style="margin-bottom: 20px">楼栋在住人数</div>
        <div style="max-width: 1608px; overflow: auto">
          <div
            id="livingPeopleBuilding"
            :style="{
              width: livingPeopleBuildingWidth,
              height: '322px',
              overflow: 'auto',
            }"
          ></div>
        </div>
      </div>
    </div>
    <div class="module">
      <div style="background: #ffffff; padding: 10px 0 10px 10px;width: 100%;box-sizing: border-box;">
        <div class="title">入住人员统计</div>
        <div
          style="
            text-align: right;
            margin-right: 64px;
            margin-bottom: 32px;
            margin-bottom: 18px;
            max-width: 1548px;
          "
        >
          <el-button
            :class="[getCheckinPersonnelStyle(1)]"
            @click="changeCheckinPersonnelData(1)"
            >按年</el-button
          >
          <el-button
            :class="[getCheckinPersonnelStyle(2)]"
            @click="changeCheckinPersonnelData(2)"
            >按月</el-button
          >
          <el-button
            :class="[getCheckinPersonnelStyle(3)]"
            @click="changeCheckinPersonnelData(3)"
            >按日</el-button
          >
          <!-- <el-date-picker
            style="margin-left: 10px"
            v-model="checkinDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-button
            type="primary"
            style="margin-left: 10px"
            @click="clickCheckinPersonnelSearch()"
            >统计</el-button
          > -->
        </div>
        <div style="max-width: 1608px; overflow: auto">
          <div
            id="check-in-personnel"
            :style="{ width: '1608px', height: '322px' }"
          ></div>
        </div>
      </div>
    </div>
    <div class="module">
      <div style="background: #ffffff; padding: 10px 0 10px 10px;width: 100%;box-sizing: border-box;">
        <div class="title">进出人员统计</div>
        <div style="text-align: right; margin-right: 64px; margin-bottom: 18px;max-width: 1548px;">
          <el-button
            :class="[getAccessPersonnelStyle(1)]"
            @click="changeAccessPersonnelData(1)"
            >按年</el-button
          >
          <el-button
            :class="[getAccessPersonnelStyle(2)]"
            @click="changeAccessPersonnelData(2)"
            >按月</el-button
          >
          <el-button
            :class="[getAccessPersonnelStyle(3)]"
            @click="changeAccessPersonnelData(3)"
            >按日</el-button
          >
          <!-- <el-date-picker
            style="margin-left: 10px"
            v-model="accessPersonnelDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-button
            type="primary"
            style="margin-left: 10px"
            @click="clickAccessPersonnelSearch()"
            >统计</el-button
          >-->
        </div>
        <div style="max-width: 1608px; overflow: auto">
          <div
            id="access-personnel"
            :style="{ width: '1608px', height: '322px' }"
          ></div>
        </div>
      </div>
    </div>
    <el-dialog
      title="数据说明"
      top="10vh"
      :visible.sync="dataDialogVisible"
      :close-on-click-modal="false"
    >
      <div>
        <div style="line-height: 25px; margin-top: -20px">
          <div class="data-title">房源数据</div>
          房源总数=当前账号下发布或管理的房源总数；<br />
          楼栋总数=当前账号下发布或管理的房源下的楼栋总数；<br />
          房间总数=当前账号下发布或管理的房间总数；<br />
          在住房间=当前账号下发布或管理的房间还处于在住状态的房间总数；<br />
          空置房数=当前账号下发布或管理的房间无人居住的房间总数；<br />
        </div>
        <div style="line-height: 25px">
          <div class="data-title">设备数据</div>
          门禁总数=当前账号下发布或管理的房源，安装的门禁总数,仅统计L2+门禁数量；<br />
          门锁总数=当前账号下发布或管理的房源，安装的门锁总数；<br />
          在线门禁=当前账号下发布或管理的门禁，当前处于在线状态的门禁总数，仅统计L2+门禁在线数量；<br />
          离线门禁=当前账号下发布或管理的门禁，当前处于离线状态的门禁总数，仅统计L2+门禁离线数量；<br />
        </div>
        <div style="line-height: 25px">
          <div class="data-title">人员数据</div>
          房管总数=房管员总人数，仅包括当前正在使用的房管员；已禁用的不包括；<br />
          在住人员总数=按照时间范围，在住、退房中或退房未结束的房间人员总数，包括房客和同住人；<br />
          退房人员总数=按照时间范围，统计已退房+退房中的人员总数；<br />
          新入住人员总数=按照时间范围，统计办理入住（排房或添加的同住人）的人员总数；<br />
          开门禁人数=按照时间范围，统计开门禁的人员总数；（手机蓝牙开门禁、远程开门禁、密码开门禁方式统计，仅统计L2+开门禁人数数量）；<br />
          开门禁人/次=按照时间范围，统计开门禁的总次数，仅统计L2+开门禁人/次数量；<br />
          开门锁人数=按照时间范围，统计开门锁的人员总数；(手机蓝牙开锁方式统计)；<br />
          开门锁人/次=按照时间范围，统计开门锁的总次数；<br />
        </div>
        <div style="line-height: 25px">
          <div class="data-title">在住用户性别统计</div>
          按照性别属性统计在住用户的数据占比情况；<br />
        </div>
        <div style="line-height: 25px">
          <div class="data-title">楼栋在住人数</div>
          按照楼栋，统计每栋楼在住用户的人数；<br />
        </div>
        <div style="line-height: 25px">
          <div class="data-title">入住人员统计</div>
          按照时间范围，统计入住人员和退房人员情况；<br />
        </div>
        <div style="line-height: 25px">
          <div class="data-title">进出人员统计</div>
          按照时间范围，统计时段内人员开门禁和开锁人员情况；<br />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      time: "",

      apartmentCount: 0,
      buildingCount: 0,
      liveRoomCount: 0,
      roomCount: 0,
      vacantRoomCount: 0,

      accessControlCount: 0,
      accessControlOfflineCount: 0,
      accessControlOnlineCount: 0,
      lockCount: 0,

      checkOutCount: 0,
      houseKeeperCount: 0,
      liveingCount: 0,
      newCheckInCount: 0,

      openAccessControlCount: 0,
      openAccessControlUserCount: 0,
      openLockCount: 0,
      openLockUserCount: 0,

      maleCount: 0,
      unknownCount: 0,
      womanCount: 0,

      dataDialogVisible: false,

      livingRoomCount: 0, //在住房数
      vacantRoomCount: 0, //空置房数
      checkinPersonnelDataType: 3,
      accessPersonnelDataType: 3,
      checkinDate: [],
      accessPersonnelDate: [],

      // 楼栋在住人数lable
      buildingNameList: [],
      // 楼栋在住人数数据
      livingCountList: [],
      livingPeopleBuildingWidth: "1608px",
      userId: "",

      checkInPersonnelLableList: [],
      checkInCountList: [],
      checkOutCountList: [],

      accessPersonnelLableList: [],
      accessControlAccessCountList: [],
      lockAccessCountList: [],

      isOverOneYearCheckinPersonne: false,
      isOverOneYearAccessPersonne: false,
      mode: "day",
    };
  },
  activated() {
    console.log("数据概览activated");
    let roleCodes = this.cache.getLS("userInfo").roleCodes;
    if(roleCodes.indexOf('housekeeper') != -1) {
      this.userId = this.cache.getLS("userInfo").parentUserId;
    }else {
      this.userId = this.cache.getLS("userInfo").userId;
    }
    

    this.checkinPersonnelDataType = 3;
    this.accessPersonnelDataType = 3;
    this.checkinDate = [];
    this.accessPersonnelDate = [];

    this.refresh();
  },

  methods: {
    getNowTime() {
      var date = new Date();
      var sign2 = ":";
      var year = date.getFullYear(); // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds(); //秒
      var weekArr = [
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
        "星期天",
      ];
      var week = weekArr[date.getDay()];
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      return (
        year +
        "/" +
        month +
        "/" +
        day +
        " " +
        hour +
        sign2 +
        minutes +
        sign2 +
        seconds
      );
    },
    // 刷新数据
    refresh() {
      this.time = this.getNowTime();

      this.getHousingData();
      this.getDeviceData();
      this.getPersonnelData();
      this.getSexData();
      this.getLivingPeopleBuildingData();
      this.changeCheckinPersonnelData(3);
      this.changeAccessPersonnelData(3);
    },
    getCheckinPersonnelStyle(type) {
      return this.checkinPersonnelDataType === type ? "active" : "";
    },
    changeCheckinPersonnelData(value) {
      this.checkinPersonnelDataType = value;
      // // 近七天
      // if (value == 3) {
      //   this.checkinDate = [this.getDay(-7), this.getDay(-1)];
      // }
      // // 上个月
      // if (value == 2) {
      //   this.checkinDate = this.lastMonth();
      // }
      // // 近半年
      // if (value == 1) {
      //   this.checkinDate = [this.getNowdate(), this.getDay(-1)];
      // }
      
      if(value == 3) {
        this.mode = "day";
      }
      if(value == 2) {
        this.mode = "month";
      }
      if(value == 1) {
        this.mode = "year";
      }

      this.getCheckinPersonnelData();
    },

    getAccessPersonnelStyle(type) {
      return this.accessPersonnelDataType === type ? "active" : "";
    },
    changeAccessPersonnelData(value) {
      this.accessPersonnelDataType = value;
      // // 近七天
      // if (value == 3) {
      //   this.accessPersonnelDate = [this.getDay(-7), this.getDay(-1)];
      // }
      // // 上个月
      // if (value == 2) {
      //   this.accessPersonnelDate = this.lastMonth();
      // }
      // // 近半年
      // if (value == 1) {
      //   this.accessPersonnelDate = [this.getNowdate(), this.getDay(-1)];
      // }

      if(value == 3) {
        this.mode = "day";
      }
      if(value == 2) {
        this.mode = "month";
      }
      if(value == 1) {
        this.mode = "year";
      }

      this.getAccessPersonnelData();
    },

    // 用户性别-图表
    creatSex() {
      const chartDom = document.getElementById("sex-pie");
      const myChart = this.$echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          left: "5%",
          left: "center",
          itemWidth: 12, // 设置图例图形的宽
          itemHeight: 12, // 设置图例图形的高
        },
        color: ["#5AC8FA", "#FFB016", "#3897F0"],
        series: [
          {
            name: "用户性别",
            type: "pie",
            radius: "65%",
            center: ["50%", "50%"],
            label: {
              formatter: "{b}: {@2012} 人",
            },
            data: [
              { value: this.maleCount, name: "男" },
              { value: this.womanCount, name: "女" },
              { value: this.unknownCount, name: "未知" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      // 图表大小跟随窗口变化
      const chartObserver = new ResizeObserver(() => {
        myChart.resize();
      });
      chartObserver.observe(chartDom);
      option && myChart.setOption(option);
    },

    // 楼栋在住人数-图表
    livingPeopleBuilding() {
      var chartDom = document.getElementById("livingPeopleBuilding");
      var myChart = this.$echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function(value) {
            const data = value[0].data
            const style = 'font-weight:bold;padding-left:5px;padding-right:5px;font-size:18px;'
            return `${data.apartmentName} ${data.name}<br>${value[0].marker} 在住${`<span style=${style}>${data.value}</span>`}人`
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.buildingNameList,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              rotate: 25,
              interval: 0,
              // textStyle: {
              //   color: 'white', // 设置坐标轴标签文字颜色
              //   fontSize: 12, // 设置坐标轴标签文字大小
              // },
              formatter: function(value) {
                // 设置最大显示长度为7个字符
                var maxLength = 6;
                if (value.length > maxLength) {
                  return value.substring(0, maxLength) + '...';
                } else {
                  return value;
                }
              }
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "（人）",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 18,
            // barGap: 20,
            // barCategoryGap: 20,
            // data: [90, 88, 80, 78, 75, 70, 65, 60, 55, 55],
            data: this.livingCountList,
            label: {
              normal: {
                show: true,
                position: "top",
                // textStyle: {
                //   color: '#ffffff',
                // },
                // formatter: function (e) {
                //   return e.data + '%'
                // },
              },
            },
            // 柱形的颜色渐变
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 1, color: "#38A8F6" },
                  { offset: 0, color: "#7D83FF" },
                ]),
                barBorderRadius: [4, 4, 4, 4],
              },
            },
          },
        ],
      };

      // 图表大小跟随窗口变化
      const chartObserver = new ResizeObserver(() => {
        myChart.resize();
      });
      chartObserver.observe(chartDom);

      option && myChart.setOption(option);
    },

    // 入住人数-图表
    checkInPersonnel() {
      const chartDom = document.getElementById("check-in-personnel");
      const myChart = this.$echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["入住", "退房"],
          // left: 'left',
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.checkInPersonnelLableList,
          // axisLabel: {
          //     interval: 3  //设置 X 轴数据间隔几个显示一个，为0表示都显示
          // },
        },
        yAxis: [
          {
            type: "value",
            name: "（人）",
          },
        ],
        color: ["#16EA76", "#FF6262"],
        series: [
          {
            name: "入住",
            type: "line",
            data: this.checkInCountList,
            smooth: 0.5, // 设置折线弧度
            label: {
              normal: {
                show: true,
                position: "top",
              },
            },
          },
          {
            name: "退房",
            type: "line",
            data: this.checkOutCountList,
            smooth: 0.5, // 设置折线弧度
            label: {
              normal: {
                show: true,
                position: "top",
              },
            },
          },
        ],
      };
      // 图表大小跟随窗口变化
      const chartObserver = new ResizeObserver(() => {
        myChart.resize();
      });
      chartObserver.observe(chartDom);
      option && myChart.setOption(option);
    },

    // 进出人员统计-图表
    accessPersonnel() {
      const chartDom = document.getElementById("access-personnel");
      const myChart = this.$echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["门禁", "门锁"],
          // left: 'left',
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.accessPersonnelLableList,
          // axisLabel: {
          //     interval: 3  //设置 X 轴数据间隔几个显示一个，为0表示都显示
          // },
        },
        yAxis: [
          {
            type: "value",
            name: "（人）",
          },
        ],
        color: ["#FFB016", "#31B9FF"],
        series: [
          {
            name: "门禁",
            type: "line",
            data: this.accessControlAccessCountList,
            label: {
              normal: {
                show: true,
                position: "top",
              },
            },
            smooth: 0.5, // 设置折线弧度
          },
          {
            name: "门锁",
            type: "line",
            data: this.lockAccessCountList,
            label: {
              normal: {
                show: true,
                position: "top",
              },
            },
            smooth: 0.5, // 设置折线弧度
          },
        ],
      };
      // 图表大小跟随窗口变化
      const chartObserver = new ResizeObserver(() => {
        myChart.resize();
      });
      chartObserver.observe(chartDom);
      option && myChart.setOption(option);
    },

    /* ---------------获取数据方法 start -------------------- */

    // 房源数据-统计
    getHousingData() {
      this.post(
        "/statistic-service/landlord/statistic/apartment-data",
        {
          userId: this.userId,
        },
        {
          isUseResponse: true,
        }
      ).then((res) => {
        if (res.data.code == 0) {
          let data = res.data.data;
          this.apartmentCount = data.apartmentCount || 0;
          this.buildingCount = data.buildingCount || 0;
          this.roomCount = data.roomCount || 0;
          this.liveRoomCount = data.liveRoomCount  || 0;
          this.vacantRoomCount = data.vacantRoomCount  || 0;
        }
      });
    },

    // 设备数据
    getDeviceData() {
      this.post(
        "/statistic-service/landlord/statistic/device-data",
        {
          userId: this.userId,
        },
        {
          isUseResponse: true,
        }
      ).then((res) => {
        if (res.data.code == 0) {
          let data = res.data.data;
          this.accessControlCount = data.accessControlCount || 0;
          this.accessControlOfflineCount = data.accessControlOfflineCount || 0;
          this.accessControlOnlineCount = data.accessControlOnlineCount || 0;
          this.lockCount = data.lockCount || 0;
        }
      });
    },

    // 人员数据-统计
    getPersonnelData() {
      // 人员
      this.post(
        "/statistic-service/landlord/statistic/user-data",
        {
          userId: this.userId,
        },
        {
          isUseResponse: true,
        }
      ).then((res) => {
        if (res.data.code == 0) {
          let data = res.data.data;
          this.checkOutCount = data.checkOutCount || 0;
          this.houseKeeperCount = data.houseKeeperCount || 0;
          this.liveingCount = data.liveingCount || 0;
          this.newCheckInCount = data.newCheckInCount || 0;
        }
      });
      // 门禁/门锁
      this.post(
        "/statistic-service/landlord/statistic/device-use-data",
        {
          userId: this.userId,
        },
        {
          isUseResponse: true,
        }
      ).then((res) => {
        if (res.data.code == 0) {
          let data = res.data.data;
          this.openAccessControlCount = data.openAccessControlCount || 0;
          this.openAccessControlUserCount = data.openAccessControlUserCount || 0;
          this.openLockCount = data.openLockCount || 0;
          this.openLockUserCount = data.openLockUserCount || 0;
        }
      });
    },

    // 用户性别数据-统计
    getSexData() {
      this.post(
        "/statistic-service/landlord/statistic/living-user-gender",
        {
          userId: this.userId,
        },
        {
          isUseResponse: true,
        }
      ).then((res) => {
        if (res.data.code == 0) {
          let data = res.data.data;
          this.maleCount = data.maleCount || 0;
          this.unknownCount = data.unknownCount || 0;
          this.womanCount = data.womanCount || 0;
          this.creatSex();
        }
      });
    },

    // 楼栋在住人数数据-统计
    getLivingPeopleBuildingData() {
      // 测试数据
      // this.peopleBuildingLable = [];
      // this.peopleBuildingNum = [];
      // for (let i = 0; i < 66; i++) {
      //   this.peopleBuildingLable.push("一单元A" + i + 1 + "栋");
      //   this.peopleBuildingNum.push(i + 10);
      // }
      // if (this.peopleBuildingNum.length > 50) {
      //   this.livingPeopleBuildingWidth = "2500px";
      // }

      // if (this.peopleBuildingNum.length > 60) {
      //   this.livingPeopleBuildingWidth = "3000px";
      // }

      // // 创建图表
      // this.livingPeopleBuilding();

      this.post(
        "/statistic-service/landlord/statistic/building-living",
        {
          userId: this.userId,
        },
        {
          isUseResponse: true,
        }
      ).then((res) => {
        this.buildingNameList = [];
        this.livingCountList = [];
        if (res.data.code == 0) {
          let data = res.data.data || [];
          data.forEach((item) => {
            this.buildingNameList.push(item.buildingName);
            this.livingCountList.push({name:item.buildingName,value:item.livingCount,apartmentName:item.apartmentName || ''});
          });

          if (this.buildingNameList.length > 50) {
            this.livingPeopleBuildingWidth = "2500px";
          }
          if (this.buildingNameList.length > 60) {
            this.livingPeopleBuildingWidth = "3000px";
          }

          this.livingPeopleBuilding();
        }
      });
    },

    // 入住人员数据
    getCheckinPersonnelData() {
      this.post(
        "/statistic-service/landlord/statistic/user-living",
        {
          userId: this.userId,
          mode: this.mode,
          // startTime: moment(this.checkinDate[0]).format("YYYY-MM-DD 00:00:00"),
          // endTime: moment(this.checkinDate[1]).format("YYYY-MM-DD 23:59:59"),
        },
        {
          isUseResponse: true,
        }
      ).then((res) => {
        this.checkInPersonnelLableList = [];
        this.checkInCountList = [];
        this.checkOutCountList = [];

        if (res.data.code == 0) {
          let data = res.data.data || [];
          data.forEach((item) => {
            this.checkInPersonnelLableList.push(item.createTime);
            this.checkInCountList.push(item.checkInCount);
            this.checkOutCountList.push(item.checkOutCount);
          });

          // 创建图表
          this.checkInPersonnel();
        }
      });
    },

    // 进出人员数据
    getAccessPersonnelData() {
      this.post(
        "/statistic-service/landlord/statistic/user-access",
        {
          userId: this.userId,
          mode: this.mode
          // startTime: moment(this.accessPersonnelDate[0]).format(
          //   "YYYY-MM-DD 00:00:00"
          // ),
          // endTime: moment(this.accessPersonnelDate[1]).format(
          //   "YYYY-MM-DD 23:59:59"
          // ),
        },
        {
          isUseResponse: true,
        }
      ).then((res) => {
        this.accessPersonnelLableList = [];
        this.accessControlAccessCountList = [];
        this.lockAccessCountList = [];
        if (res.data.code == 0) {
          let data = res.data.data || [];
          data.forEach((item) => {
            this.accessPersonnelLableList.push(item.createTime);
            this.accessControlAccessCountList.push(
              item.accessControlAccessCount
            );
            this.lockAccessCountList.push(item.lockAccessCount);
          });

          // 创建图表
          this.accessPersonnel();
        }
      });
    },

    /* ---------------获取数据方法 end -------------------- */

    //获取日期 day：-7即七天前
    getDay(day) {
      var today = new Date();
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码
      var tYear = today.getFullYear();
      var tMonth = today.getMonth() + 1;
      var tDate = today.getDate();
      if (tMonth < 10) {
        tMonth = "0" + tMonth;
      }
      if (tDate < 10) {
        tDate = "0" + tDate;
      }
      var lastWeekFirstDaty = tYear + "-" + tMonth + "-" + tDate;
      return lastWeekFirstDaty;
    },
    // 上个月
    lastMonth() {
      var nowdays = new Date();
      var year = nowdays.getFullYear();
      var month = nowdays.getMonth();
      if (month == 0) {
        month = 12;
        year = year - 1;
      }
      if (month < 10) {
        month = "0" + month;
      }

      var myDate = new Date(year, month, 0);

      var startDate = year + "-" + month + "-01 00:00:00"; //上个月第一天
      var endDate = year + "-" + month + "-" + myDate.getDate() + " 23:59:59"; //上个月最后一天
      let lastMonth = [startDate, endDate];
      return lastMonth;
    },
    // 半年前 的时间
    getNowdate(time = 6) {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth();
      let sdate = date.getDate();
      let olddate = new Date(year, month - time, sdate).getTime();
      return this.format(olddate);
    },

    // 点击入住人员统计按钮
    clickCheckinPersonnelSearch() {
      let startDate = moment(this.checkinDate[0]).format("YYYY-MM-DD");
      let endDate = moment(this.checkinDate[1]).format("YYYY-MM-DD");
      let type = 'checkIn';
      this.checkIsOneYear(startDate, endDate, type);

      if (!this.isOverOneYearCheckinPersonne) {
        this.getCheckinPersonnelData();
      }
    },

    // 点击进出人员统计按钮
    clickAccessPersonnelSearch() {
      let startDate = moment(this.accessPersonnelDate[0]).format("YYYY-MM-DD");
      let endDate = moment(this.accessPersonnelDate[1]).format("YYYY-MM-DD");
      let type = 'access';
      this.checkIsOneYear(startDate, endDate, type);

      if (!this.isOverOneYearAccessPersonne) {
        this.getAccessPersonnelData();
      }
    },

    checkIsOneYear(startDate, endDate, type) {
      //平年每年的毫秒数
      var ms = 31536000000;
      //将yyyy-mm-dd时间转成数组
      var startArr = startDate.split("-");
      var endArr = endDate.split("-");
      //生成Date数据
      var start = new Date(
        parseInt(startArr[0]),
        parseInt(startArr[1]) - 1,
        parseInt(startArr[2]),
        0,
        0,
        0
      );
      var end = new Date(
        parseInt(endArr[0]),
        parseInt(endArr[1]) - 1,
        parseInt(endArr[2]),
        0,
        0,
        0
      );

      //取出年月，用于判断是否为闰年
      var startYear = start.getFullYear();
      var startMonth = start.getMonth();
      var endYear = end.getFullYear();
      var endMonth = end.getMonth();
      //闰年判断
      if (this.isLeapYear(startYear) && startMonth <= 2) {
        //加一天
        ms += 86400000;
      } else if (this.isLeapYear(endYear) && endMonth >= 2) {
        ms += 86400000;
      }

      //结束时间-开始时间的毫秒数
      var intervarMs = Number(end) - Number(start);
      this.isOverOneYearCheckinPersonne = false;
      this.isOverOneYearAccessPersonne = false;
      if (intervarMs > 0) {
        if (intervarMs >= ms) {
          if (type == "checkIn") {
            this.isOverOneYearCheckinPersonne = true;
          } else {
            this.isOverOneYearAccessPersonne = true;
          }

          return this.$message({
            showClose: true,
            message: "开始日期距结束日期跨度超过一年",
            type: "warning",
          });
        }
      } else {
        if (type == "checkIn") {
          this.isOverOneYearCheckinPersonne = true;
        } else {
          this.isOverOneYearAccessPersonne = true;
        }
        return this.$message({
          showClose: true,
          message: "开始日期不能晚于结束日期",
          type: "warning",
        });
      }
    },

    isLeapYear(year) {
      if (year % 100 == 0) {
        if (year % 400 == 0) {
          return true;
        }
      } else if (year % 4 == 0) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scope>
.landlordData-overview {
  background: #f6f6f6;
  margin: -10px -20px;
  padding: 35px;
  font-size: 14px;
  overflow: auto;
  .module {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
  }
  .refresh-box {
    height: 16px;
    line-height: 16px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-right: 20px;
  }
  .refresh-btn {
    color: #333;
    padding: 0;
    margin-left: 3px;
  }
  .title {
    border-left: 4px solid #30bac1;
    height: 16px;
    line-height: 16px;
    padding-left: 7px;
    background: #ffffff;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .content {
    background: #ffffff;
    // width: 794px;
    // height: 182px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .line {
    background: #dcdfe6;
    width: 1px;
    height: 122px;
  }

  .line-x {
    background: #dcdfe6;
    height: 1px;
    margin: 25px;
  }

  .count-num {
    font-size: 30px;
    width: 130px;
    word-break: break-word;
  }

  .module-box {
    text-align: center;
    img {
      width: 56px;
      height: 56px;
      margin-top: 11px;
      margin-bottom: 6px;
    }
  }

  .module-box-title {
    font-size: 14px;
    color: #666666;
  }

  .cabinet-content-title {
    font-size: 12px;
    color: #888888;
  }

  .module-content {
    width: 50%;
    height: 100%;
    padding: 10px 10px 26px 10px;
    background: #ffffff;
  }
  .data-title {
    font-weight: bold;
    margin: 10px 0 0;
  }

  .active {
    color: #30bac1;
    border-color: #30bac1;
  }

  .notData {
    width: 1608px;
    height: 322px;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
</style>